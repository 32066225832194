<template>
  <div class="justify-center d-flex " :class="{'flex-column align-center': item.marker}">
    <h5 class="text-uppercase">{{ item.type }}</h5>
    <v-avatar
      :color="item.avatar ? '' : 'primary'"
      :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
      :size="item.marker?70:30"
      rounded
    >
      <v-img
        v-if="item.avatar"
        class="  my-auto"
        :lazy-src="require(`@/assets/images/avatars/1.png`)"
        :src="item.avatar || require(`@/assets/images/avatars/1.png`)"
      ></v-img>
      <span
        v-else
        class="font-weight-semibold text-body-2"
      >{{ avatarText(item.label ) }}</span>
    </v-avatar>
    <span v-if="item.publication" class="ml-1 my-auto">{{ item.publication }} - </span>
    <span class="ml-1 my-auto">{{ item.label }} <br v-if="item.address"/> {{ item.address }} <br v-if="item.email"/>  {{ item.email }} <br v-if="item.tel"/>  {{ item.tel }}
      <br v-if="item.qualities && item.qualities.length" />  {{ item.qualities && item.qualities.length?`Qualité texte: ${item.qualities}`:'' }}</span>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.options,
      set: value => emit('update:object', value),
    })

    return {
      item,
      avatarText,
    }
  },
}
</script>
